import Image from 'next/image'
import Link from 'next/link'

type Props = {
  isLandscape?: boolean
}

export const CustomAdsCard = ({ isLandscape = false }: Props) => {
  return (
    <Link href="https://opthub.ai/ja/competitions/eccomp2024">
      <a>
        {isLandscape ? (
          <Image
            src="/ads/landscape.png"
            width={850}
            height={90}
            alt="OptHub AI Competition"
          />
        ) : (
          <Image
            src="/ads/normal.png"
            width={640}
            height={500}
            alt="OptHub AI Competition"
          />
        )}
      </a>
    </Link>
  )
}
